.category-round{
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 12px;
    gap: 12px;
    text-align: center;
    align-items: center;

    .category-round-img{
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 100px;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
        width: 75px;
        height: 75px;
        flex-shrink: 0;

        @media (max-width: 992px) {
            width: 70px;
            height: 70px;
        }

        @media (max-width: 768px) {
            width: 65px;
            height: 65px;
        }

        img{
            width: 100%;
            object-fit: cover;
        }

        a{
            text-decoration: none;
        }
    }

    .category-name{
        font-size: 12px;
        font-weight: 600;
        color: #333;
        text-decoration: none;

        @media (max-width: 768px) {
            font-size: 10px;
        }
    }
}

.js-categoryRoundCarousel.skeleton-loaderCarousel .categoryRoundCarousel .category-round{
    height: 130px !important;
    @media (max-width: 576px) {
        height: 130px !important;
    }
}

.experience-dynamic-dynamicCategoriesRound .category-round .category-round-img{
    margin-bottom: 12px;
}